import React, { useState, useEffect } from 'react';

const Footer = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);

  return (
    <footer className="py-6 px-4 mt-24">
      <div className="max-w-6xl mx-auto flex justify-between items-center text-xs">
        <div>
          <p>Time, Date</p>
          <div className="flex">
            <div className="w-16 text-left">{currentTime.toTimeString().slice(0,8)}</div>
            {/* <div className="w-6 text-center"> - </div> */}
            <div>{`${currentTime.getMonth() + 1}/${currentTime.getDate()}/${currentTime.getFullYear()}`}</div>
          </div>
        </div>
        <div className="text-right">
          <p>Latitude, Longitude</p>
          <p>(37.774929, -122.419418)</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;