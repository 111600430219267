import React from 'react';

const SectionSeparator = ({ width = '200px', thickness = '1px', color = '#CBD5E0', margin = 'my-12' }) => {
  return (
    <div className={`flex justify-center ${margin}`}>
      <div 
        className={`bg-gray-300 rounded-full ${margin}`} 
        style={{ 
          width, 
          height: thickness 
        }}
      />
    </div>
  );
};

export default SectionSeparator;