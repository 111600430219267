export const jobListings = [
  {
    id: "ml-engineer",
    title: "ML Research Engineer",
    location: "San Francisco, CA",
    location_secondary: "San Francisco, CA • We Sponsor Visas",
    type: "Full-time",
    shortDescription: "Build and deploy state-of-the-art machine learning models for our AI systems.",
    description: [
      "We are seeking a talented Machine Learning Engineer to join our team and help develop cutting-edge AI solutions. In this role, you will work closely with our research and product teams to design, implement, and optimize machine learning models.",
      "Our ideal candidate has strong experience in modern deep learning frameworks and a track record of taking models from research to production."
    ],
    responsibilities: [
      "Design and develop machine learning models for various AI applications",
      "Collaborate with research team to implement and scale state-of-the-art algorithms",
      "Optimize model performance, both in terms of accuracy and computational efficiency",
      "Work with engineering teams to integrate ML models into our product infrastructure",
      "Stay current with the latest advancements in ML/AI research and technologies"
    ],
    requirements: [
      "Strong programming skills in Python and familiarity with ML frameworks like PyTorch or TensorFlow",
      "Actively keeping up to date with the latest research",
      "Experience with large language models (LLMs) and transformer architectures"
    ],
    niceToHave: [
      "Experience with distributed training and large-scale model deployment",
      "Contributions to open-source ML projects",
      "Publications in ML/AI conferences",
      "Experience with cloud platforms (AWS, GCP, Azure)"
    ]
  },
  {
    id: "cracked-student",
    title: "Cracked College Student",
    location: "San Francisco, CA",
    location_secondary: "San Francisco, CA • We Sponsor Visas",
    type: "Full-time",
    shortDescription: "Build and deploy state-of-the-art machine learning models for our AI systems.",
    description: [
      "You have built a bunch of side projects, and love making things work.",
    ],
    requirements: [
      "Strong programming skills in Python",
      "Excited by ML research and solving really hard problems",
      "Can ship really, really fast.",
      "Experienced with cloud platforms (AWS, GCP, Azure)"
    ],
    niceToHave: [
      "Experience with training and model deployment",
      "Contributions to open-source ML projects",
    ]
  },
  {
    id: "software-engineer",
    title: "Software Engineer, AI Infrastructure and Data",
    location: "San Francisco, CA",
    location_secondary: "San Francisco, CA • We Sponsor Visas",
    type: "Full-time",
    shortDescription: "Build and maintain the infrastructure that powers our advanced AI systems.",
    description: [
      "We're looking for an experienced Software Engineer to join our AI Infrastructure team. In this role, you'll be responsible for building and maintaining the systems that power our AI models at scale.",
      "You'll work closely with our ML engineers and researchers to create efficient, reliable, and scalable infrastructure that supports our most demanding workloads."
    ],
    responsibilities: [
      "Design and implement scalable services for AI model training and inference",
      "Build and optimize data pipelines for large-scale machine learning",
      "Improve system reliability, monitoring, and observability",
      "Collaborate with ML engineers to optimize model deployment workflows",
      "Contribute to our internal tools and frameworks"
    ],
    requirements: [
      "BS/MS in Computer Science or equivalent practical experience",
      "5+ years of software engineering experience",
      "Strong programming skills in Python and at least one compiled language (Go, C++, Rust)",
      "Experience with distributed systems and cloud services",
      "Knowledge of containerization and orchestration (Docker, Kubernetes)"
    ],
    niceToHave: [
      "Experience with ML frameworks and serving infrastructure",
      "Familiarity with GPU programming and optimization",
      "Experience with high-performance computing",
      "Contributions to open-source projects"
    ]
  },
  {
    id: "research-scientist",
    title: "General Application",
    location: "San Francisco, CA",
    location_secondary: "San Francisco, CA • We Sponsor Visas",
    type: "Full-time",
    shortDescription: "Push the boundaries of AI by conducting cutting-edge research in natural language processing and multimodal learning.",
    description: [
      "We are always looking for cracked individuals to join our team. If you are interested in working with us but don't see a specific role that fits your background, please apply regardless.",
    ],
    responsibilities: [],
    requirements: [],
    niceToHave: []
  }
]; 