import React from 'react';
import Footer from './Footer';

const Mission = () => {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      {/* Main Content */}
      <main className="flex-grow px-4">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-normal text-center mb-16">Our Mission</h1>

          <div className="space-y-12">
            <p className="text-lg text-center">
              We're building digital humans that are indistinguishable from real humans
            </p>

            <div className="mt-8">
              <p className="text-lg mb-2">Specifically, they:</p>
              <ul className="list-disc pl-8 space-y-2">
                <li className="text-lg">Hold real-time, multimodal video interactions that look and sound ultra-realistic</li>
                <li className="text-lg">Intelligently control their body: write, gesticulate, clap, etc.</li>
                <li className="text-lg">Have intelligent spatial awareness: walk around and interact with their environment</li>
              </ul>
            </div>
            <div className="flex justify-center my-12 py-8">
              <img
                src="/assets/images/mission.png"
                alt="Canopy Labs Model Diagram"
                className="w-full max-w-2xl"
              />
            </div>
            <p className="text-lg text-center mb-20 max-w-2xl mx-auto">
              Backed by a16z speedrun, SV Angel, Z Fellows, BoxGroup, Sunflower, Pareto
            </p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Mission;