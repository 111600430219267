import React, { useState } from 'react';
import CanopyLabsModelReleases from './CanopyLabsModelReleases';
import Mission from './Mission';
import Header from './Header';
import JobsPage from './JobsPage';
import JobDescription from './JobDescription';
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import './posthog' // Import PostHog configuration (no need to assign it to a variable)


const App = () => {
  const [currentPath, setCurrentPath] = useState('/our-mission');

  // Handle navigation without page refresh
  const handleNavigate = (path) => {
    setCurrentPath(path);
    window.history.pushState({}, "", path);
  };

  // Extract the page name from the path for the Header component
  const getCurrentPage = () => {
    if (currentPath === '/model-releases') return 'model-releases';
    if (currentPath === '/open-positions' || currentPath.startsWith('/open-positions/')) return 'open-positions';
    if (currentPath === '/our-mission') return 'our-mission';
    return 'our-mission';
  };

  // Intercept link clicks to handle routing
  React.useEffect(() => {
    const handleClick = (e) => {
      // Only handle clicks on internal links
      if (e.target.tagName === 'A' && e.target.href.startsWith(window.location.origin)) {
        e.preventDefault();
        const path = new URL(e.target.href).pathname;
        handleNavigate(path);
      }
    };

    // Listen for clicks
    document.addEventListener('click', handleClick);
    
    // Initialize path from URL
    const initialPath = window.location.pathname;
    if (initialPath !== currentPath) {
      setCurrentPath(initialPath);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [currentPath]);

  // Render the correct page based on path
  const renderPage = () => {
    if (currentPath === '/model-releases') {
      return <CanopyLabsModelReleases />;
    } else if (currentPath === '/open-positions') {
      return <JobsPage handleNavigate={handleNavigate} />;
    } else if (currentPath.startsWith('/open-positions/')) {
      const jobId = currentPath.replace('/open-positions/', '');
      return <JobDescription jobId={jobId} handleNavigate={handleNavigate} />;
    } else {
      return <Mission />;
    }
  };

  return (
    <PostHogProvider client={posthog}>
      <div className="flex flex-col min-h-screen font-sans text-gray-900">
        <Header currentPage={getCurrentPage()} />
        {renderPage()}
      </div>
    </PostHogProvider>
  );
};

export default App;