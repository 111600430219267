import React, { useState } from 'react';

const Header = ({ currentPage }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      {/* Desktop Header (hidden on small screens) */}
      <div className="hidden sm:block">
        {/* Header */}
        <header className="py-8 px-4">
          <div className="max-w-6xl mx-auto flex justify-center">
            <div className="text-center">
              <p className="text-sm">Canopy Labs</p>
            </div>
          </div>
        </header>

        {/* Navigation */}
        <nav className="mb-20">
          <div className="flex justify-center">
            <div className="grid grid-cols-3 gap-12 w-96 whitespace-nowrap">
              <div className="flex justify-end">
                <a 
                  href="/model-releases" 
                  className={`text-sm relative group transition-colors ${currentPage === 'model-releases' ? 'text-black' : 'text-gray-500 hover:text-gray-800'}`}
                >
                  <span className="inline-block">Model Releases</span>
                  {currentPage === 'model-releases' && <span className="absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-[20px] h-[1px] bg-current"></span>}
                  <span className="absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-[20px] h-[1px] bg-current opacity-0 group-hover:opacity-100 transition-opacity"></span>
                </a>
              </div>

              <div className="flex justify-center">
                <a 
                  href="/our-mission" 
                  className={`text-sm relative group transition-colors ${currentPage === 'our-mission' ? 'text-black' : 'text-gray-500 hover:text-gray-800'}`}
                >
                  <span className="inline-block">Our Mission</span>
                  {currentPage === 'our-mission' && <span className="absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-[20px] h-[1px] bg-current"></span>}
                  <span className="absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-[20px] h-[1px] bg-current opacity-0 group-hover:opacity-100 transition-opacity"></span>
                </a>
              </div>

              <div className="flex justify-start">
                <a 
                  href="/open-positions" 
                  className={`text-sm relative group transition-colors ${currentPage === 'open-positions' ? 'text-black' : 'text-gray-500 hover:text-gray-800'}`}
                >
                  <span className="inline-block">Open Positions</span>
                  {currentPage === 'open-positions' && <span className="absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-[20px] h-[1px] bg-current"></span>}
                  <span className="absolute bottom-[-3px] left-1/2 transform -translate-x-1/2 w-[20px] h-[1px] bg-current opacity-0 group-hover:opacity-100 transition-opacity"></span>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Mobile Header (shown only on small screens) */}
      <div className="block sm:hidden">
        <header className="py-4 px-4 flex justify-between items-center">
          <div className="text-sm font-medium">Canopy Labs</div>
          
          {/* Mobile menu button */}
          <button 
            className="p-2 text-black"
            onClick={() => setIsMobileMenuOpen(true)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        
        <div className="h-10 mb-4"></div> {/* Spacer */}
      </div>

      {/* Mobile menu overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 z-50 bg-white sm:hidden">
          <div className="p-4">
            <div className="flex justify-between items-center mb-8">
              <div className="text-sm font-medium">Canopy Labs</div>
              <button 
                onClick={() => setIsMobileMenuOpen(false)}
                className="p-2 text-black"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            
            <nav className="flex flex-col items-center space-y-8 mt-10">
              <a 
                href="/model-releases" 
                className={`text-sm relative ${currentPage === 'model-releases' ? 'text-black' : 'text-gray-500'}`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Model Releases
                {currentPage === 'model-releases' && <div className="h-[1px] w-10 bg-current mt-1 mx-auto"></div>}
              </a>
              <a 
                href="/our-mission" 
                className={`text-sm relative ${currentPage === 'our-mission' ? 'text-black' : 'text-gray-500'}`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Our Mission
                {currentPage === 'our-mission' && <div className="h-[1px] w-10 bg-current mt-1 mx-auto"></div>}
              </a>
              <a 
                href="/open-positions" 
                className={`text-sm relative ${currentPage === 'open-positions' ? 'text-black' : 'text-gray-500'}`}
                onClick={() => setIsMobileMenuOpen(false)}
              >
                Open Positions
                {currentPage === 'open-positions' && <div className="h-[1px] w-10 bg-current mt-1 mx-auto"></div>}
              </a>
            </nav>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;