import React from 'react';

const PromptAudioTable = () => {
  // Sample data - you can replace this with your actual data
  const samples = [
    {
      id: 3,
      prompt: "He qualified for the national tournament. <normal>",
      audioSrc: "/assets/audio/zoe/3.wav"
    }, 
    {
      id: 5,
      prompt: "He qualified for the national tournament. <slow>",
      audioSrc: "/assets/audio/zoe/5.wav"
    },
    {
      id: 2,
      prompt: "He qualified for the national tournament. <crying>",
      audioSrc: "/assets/audio/zoe/2.wav"
    },

    {
      id: 4,
      prompt: "He qualified for the national tournament. <sleepy>",
      audioSrc: "/assets/audio/zoe/4.wav"
    }, 
    {
      id: 1,
      prompt: "<sigh/> He qualified for the national tournament. <normal>",
      audioSrc: "/assets/audio/zoe/1.wav"
    },

    {
      id: 6,
      prompt: "The, uhm, men at those, <chuckle>, fundraisers are always SO serious. <normal>",
      audioSrc: "/assets/audio/zoe/7.wav"
    }, 
  ];

  return (
    <div className="w-full p-0">
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-3 text-left border border-gray-300 w-1/3">Audio</th>
            <th className="p-3 text-left border border-gray-300 w-2/3">Prompt</th>
          </tr>
        </thead>
        <tbody>
          {samples.map((sample) => (
            <tr key={sample.id} className="border-b border-gray-200">
              <td className="p-3 border border-gray-300">
                <audio controls className="w-full">
                  <source src={sample.audioSrc} type="audio/wav" />
                  Your browser does not support the audio element.
                </audio>
              </td>
              <td className="p-3 border border-gray-300">
                <div className="text-gray-700 font-mono text-sm">
                  {sample.prompt}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PromptAudioTable;