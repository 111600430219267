import React from 'react';

const AudioTableKey = () => {
  return (
    <div className="mt-4 text-sm text-gray-500">
      <p><span className="font-medium text-gray-700">Orpheus:</span> Sample was passed in prompt along with text to generate</p>
      <p><span className="font-medium text-gray-700">ElevenLabs & PlayHT:</span> Sample was given to instant voice cloning</p>
    </div>
  );
};

export default AudioTableKey;