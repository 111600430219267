import React from 'react';

const AudioComparisonTable = ({ audioSets }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200 table-fixed">
        <thead>
          <tr>
            {audioSets.providers.map((provider, index) => (
              <th key={index} className="px-3 py-3 text-left text-sm font-medium text-gray-700 tracking-wider w-1/3">
                {provider}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {audioSets.rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((audio, colIndex) => (
                <td key={colIndex} className="px-3 py-4">
                  <audio controls className="w-full">
                    <source src={audio.src} type={audio.type || "audio/wav"} />
                    Your browser does not support the audio element.
                  </audio>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// Example usage:
// For the zero-shot voice cloning set
const zeroShotAudioSet = {
  providers: ['Orpheus', 'ElevenLabs', 'PlayHT'],
  rows: [
    [
      { src: "/assets/audio/zero_shot/orpheus/zst-angry.wav" },
      { src: "/assets/audio/zero_shot/eleven_labs/angry.mp3", type: "audio/mp3" },
      { src: "/assets/audio/zero_shot/playht/angry.wav" }
    ],
    [
      { src: "/assets/audio/zero_shot/orpheus/zst-sad.wav" },
      { src: "/assets/audio/zero_shot/eleven_labs/sad.mp3", type: "audio/mp3" },
      { src: "/assets/audio/zero_shot/playht/sad.wav" }
    ],
    [
      { src: "/assets/audio/zero_shot/orpheus/zst-happy.wav" },
      { src: "/assets/audio/zero_shot/eleven_labs/happy.mp3", type: "audio/mp3" },
      { src: "/assets/audio/zero_shot/playht/happy.wav" }
    ]
  ]
};

// For the natural speech samples
const naturalSpeechAudioSet = {
  providers: ['Orpheus (Ours)', 'ElevenLabs', 'PlayHT'],
  rows: [
    [
      { src: "/assets/audio/natural/orpheus/2.wav" },
      { src: "/assets/audio/natural/elevenlabs/2.wav" },
      { src: "/assets/audio/natural/playht/2.wav" }
    ],
    [
      { src: "/assets/audio/natural/orpheus/4.wav" },
      { src: "/assets/audio/natural/elevenlabs/4.wav" },
      { src: "/assets/audio/natural/playht/4.wav" }
    ],
    [
      { src: "/assets/audio/natural/orpheus/1.wav" },
      { src: "/assets/audio/natural/elevenlabs/1.wav" },
      { src: "/assets/audio/natural/playht/1.wav" }
    ]
  ]
};

export default AudioComparisonTable;
export { zeroShotAudioSet, naturalSpeechAudioSet };