import React, { useState, useEffect } from 'react';

const ContentsSidebar = () => {
  const [activeSection, setActiveSection] = useState('');
  
  // Define sections from the page
  const sections = [
    { id: 'introduction', title: 'Introducing Orpheus TTS', offset: 0 },
    { id: 'capabilities', title: 'Exploring Capabilities', offset: -20 },
    { id: 'speaking-like-human', title: 'Speaking Like a Human', offset: -20 },
    { id: 'demo', title: 'Try a Demo', offset: -20 },
    { id: 'voice-cloning', title: 'Natural Zero-Shot Voice Cloning', offset: -20 },
    { id: 'emotion', title: 'Guided Emotion and Intonation', offset: -20 },
    { id: 'production', title: 'In Production Usage', offset: -20 },
    { id: 'stay-updated', title: 'Stay Updated', offset: -20 },
  ];

  // Function to scroll to a section
  const scrollToSection = (id, offset = 0) => {
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  // Track scroll position to highlight active section
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + 150; // Offset for better highlighting

      // Find the current section based on scroll position
      for (let i = sections.length - 1; i >= 0; i--) {
        const element = document.getElementById(sections[i].id);
        if (element && element.offsetTop <= scrollPosition) {
          setActiveSection(sections[i].id);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial active section

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="hidden lg:block sticky top-20 px-4 w-56 max-h-screen overflow-y-auto">
      <div className="text-gray-900 font-medium mb-4">Contents</div>
      <ul className="space-y-2">
        {sections.map((section) => (
          <li key={section.id}>
            <button
              onClick={() => scrollToSection(section.id, section.offset)}
              className={`text-left w-full px-2 py-1 text-sm rounded hover:text-gray-800 hover:bg-gray-100 transition-colors ${
                activeSection === section.id
                  ? 'text-gray-800 font-medium'
                  : 'text-gray-500'
              }`}
            >
              {section.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContentsSidebar;