import React from 'react';
import { jobListings } from './jobData';
import Footer from './Footer';

const JobsPage = ({ handleNavigate }) => {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <main className="flex-grow px-4">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl font-normal text-center mb-16">Join Us</h1>
          
          <p className="text-lg text-center mb-6 max-w-2xl mx-auto">
          Join our team leading the future of interfacing with AI
          </p>
          <div className="relative">
            <div className="border-l border-gray-300 pl-8 space-y-12">
              {jobListings.map((job) => (
                <div 
                  key={job.id} 
                  className="cursor-pointer group transition-colors"
                  onClick={() => handleNavigate(`/open-positions/${job.id}`)}
                >
                  <h2 className="text-2xl font-normal mb-1 group-hover:text-gray-600 flex items-center">
                    {job.title}
                    <svg 
                      className="ml-2 h-5 w-5 transition-transform group-hover:translate-x-1" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M14 5l7 7m0 0l-7 7m7-7H3" 
                      />
                    </svg>
                  </h2>
                  <p className="text-sm mb-4 group-hover:text-gray-600">{job.location}</p>
                </div>
              ))}
            </div>
            {/* Horizontal line at bottom of vertical line */}
            <div className="absolute bottom-0 left-0 w-4 h-px bg-gray-300"></div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default JobsPage; 