import React from 'react';
import { jobListings } from './jobData';
import Footer from './Footer';

const JobDescription = ({ jobId, handleNavigate }) => {
  // Find the job with matching ID
  const job = jobListings.find(job => job.id === jobId);

  // Handle if job not found
  if (!job) {
    return (
      <div className="flex flex-col min-h-screen font-sans text-gray-900">
        <main className="flex-grow px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl font-normal mb-8">Position Not Found</h1>
            <p className="text-lg mb-8">Sorry, we couldn't find the position you're looking for.</p>
            <button
              className="text-gray-900 hover:underline font-normal"
              onClick={() => handleNavigate('/open-positions')}
            >
              View All Positions
            </button>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <main className="flex-grow px-4">
        <div className="max-w-3xl mx-auto">
          <button
            className="mb-8 text-gray-900 hover:underline flex items-center font-normal"
            onClick={() => handleNavigate('/open-positions')}
          >
            ← Back
          </button>

          <div className="relative">
            <div className="border-l border-gray-300 pl-8 pb-8">
              <h1 className="text-4xl font-normal mb-4">{job.title}</h1>
              <p className="text-sm mb-4">{job.location_secondary}</p>

              {/* Application instructions at the top */}
              <div className="bg-gray-50 p-4 rounded-lg mb-8 border border-gray-200">
                <p className="font-medium">To Apply:</p>
                <p>Email your CV and Github to <a href="mailto:jobs@canopylabs.ai" className="text-blue-600 hover:underline">jobs@canopylabs.ai</a> with a few bullet points about your best work.</p>
              </div>

              <div className="space-y-8">
                {job.description.map((paragraph, idx) => (
                  <p key={idx} className="text-lg">{paragraph}</p>
                ))}

                {job.responsibilities && job.responsibilities.length > 0 && (
                  <div>
                    <h2 className="text-2xl font-normal mb-4">Responsibilities</h2>
                    <ul className="list-disc pl-8 space-y-2">
                      {job.responsibilities.map((responsibility, idx) => (
                        <li key={idx} className="text-lg">{responsibility}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {job.requirements && job.requirements.length > 0 && (
                  <div>
                    <h2 className="text-2xl font-normal mb-4">What we're looking for</h2>
                    <ul className="list-disc pl-8 space-y-2">
                      {job.requirements.map((requirement, idx) => (
                        <li key={idx} className="text-lg">{requirement}</li>
                      ))}
                    </ul>
                  </div>
                )}

                {job.niceToHave && job.niceToHave.length > 0 && (
                  <div>
                    <h2 className="text-2xl font-normal mb-4">Nice to Have</h2>
                    <ul className="list-disc pl-8 space-y-2">
                      {job.niceToHave.map((item, idx) => (
                        <li key={idx} className="text-lg">{item}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {/* Horizontal line at bottom of vertical line */}
            <div className="absolute bottom-0 left-0 w-4 h-px bg-gray-300"></div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default JobDescription; 