import posthog from 'posthog-js'

// Initialize PostHog with your project API key from environment variables
posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  loaded: (posthog) => {
    if (process.env.NODE_ENV === 'development') {
      // Uncomment this if you want to disable analytics in development
      // posthog.opt_out_capturing()
    }
  },
  capture_pageview: true, // Automatically capture page views
  
  // Enable session recording
  session_recording: {
    enabled: true,
    recordCanvas: true,
    maskAllInputs: false, // Set to true if you want to mask all input values
    maskInputOptions: {
      password: true,
      email: false, // Set to true if you want to mask email inputs
      // Add other sensitive fields you want to mask
    }
  }
})

export default posthog 